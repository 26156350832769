import React from 'react';
import { Router, Location } from '@reach/router';
import Loadable from 'react-loadable';
import Loading from 'components/loading.js'; // custom loading component displayed while the page is loading.

import PrivateRoute from 'routes/PrivateRoute'; // custom route component for handling private routes.





const LoadableLayout = Loadable({ // Loads the layout component for the dashboard asynchronously.
  loader: () => import('layouts/Admin'),
  loading: Loading
});

const LoadableDashboard = Loadable({ // Loads the main dashboard component asynchronously.
  loader: () => import('views/dashboard'),
  loading: Loading
});

const LoadableChart = Loadable({ 
  loader: () => import('views/drilldown/Chart'),
  loading: Loading
});

const LoadableShare = Loadable({ 
  loader: () => import('views/share/RedirectShare'),
  loading: Loading
});

const DashboardClient = () => (
  <Location>
    {({ location }) => (
      <LoadableLayout>
        <Router basepath="/dashboard" location={location}>
          <PrivateRoute component={LoadableDashboard} path="/" />
          <PrivateRoute component={LoadableDashboard} path="/home" />
          <PrivateRoute component={LoadableChart} state={location.state} path="/chart" />
          <PrivateRoute component={LoadableShare} path="/:shareId" />
        </Router>
      </LoadableLayout>
    )}
  </Location>
);

export default DashboardClient;
